/*
 * @Author: 尚宏浩 aaa@hisense.com
 * @Date: 2024-08-21 08:52:02
 * @LastEditors: 尚宏浩 aaa@hisense.com
 * @LastEditTime: 2024-08-22 16:39:43
 * @FilePath: \B2B\src\views\haixinReturn\defectiveReturnList\api.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// import http from "@/utils/request";
import http from "@/utils/request";
export function getList(params) {
  return http({
    method: "get",
    url: "/returnOrder/list.nd",
    params
  });
}

// 加入购物车
export function addCart(params) {
    return http({
      method: "get",
      url: "/cart/addToCartNew.nd",
      params
    });
  }
// 导出
export function exportOrder(params) {
    return http({
      method:'post',
      url:'/returnOrder/export.nd',
      params,
      responseType: 'blob'
    })
}

// /defectiveProduct/update.nd?id=13964978418&audit=通过&auditRemark=驳回原因
export function updateStatus(params) {
  return http({
    method: "get",
    url: "/defective/audit.nd",
    params
  });
}