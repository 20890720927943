import ProductItem from "@/components/global/OrderDetail/ProductItem.vue";
import {getOrderDetail} from './api'
import cancelModal from "./../../channelReturns/distributorReturnList/components/cancelModal";
import Process from './components/Process'
import ProcessFX from './components/ProcessFX'
import bg from '@/assets/purchaseOrder/bg.png'
import moment from "moment";
// import {cancelOrder} from '../components/util'
moment.locale("zh-cn");
import Btn from "@/components/global/Btn/Btn";
import {addCart, cancleChannelOrder} from "@/views/order/api";
import checkComputation from '../components/checkComputation'
import { updateStatus } from "./../defectiveReturnList/api";
import { mapState } from "vuex";
import JsBarcode from 'jsbarcode'
import TotalPrice from './components/TotalPrice.vue'
export default {
  name: "purchaseOrderDetail",
  components: { ProductItem,Process,ProcessFX,Btn,checkComputation,cancelModal,TotalPrice},
  data() {
    return {
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/hisenseReturn/hisenseReturnList",
          name: "hisenseReturnList",
          title: "海信退货明细"
        },
        {
          path: "",
          name: "",
          title: "退货详情"
        }
      ],
      orderDetail:{
        salesDefective:{}
      },
      pageLoadFlag:false,
      bg:bg,
      visible:true,
      openType: '',
      visibleshop: false,
      salesOrderId:'',
      saleItem:{},
      visibleCancel:false,
      appraisalcolumns:[
        {
          title: '行号',
          dataIndex: 'lineNum',
          customRender:(text,record,index)=>`${index+1}`,
          width: 80,
          ellipsis: true
        },
        {
          title: '产品型号',
          dataIndex: 'productModel',
          width:200,
          ellipsis: true
        },
        {
          title: '物料编码',
          dataIndex: 'prdMatnrName',
          width:200,
          ellipsis: true
        },
        {
          title: '机编码',
          dataIndex: 'machineNum',
          width:200,
          ellipsis: true
        },
        {
          title: 'CRM鉴定工单号',
          dataIndex: 'crmCode',
          width:150,
          ellipsis: true
        },
        {
          title: '特殊标识',
          dataIndex: 'specialFlag',
          width:150,
          ellipsis: true
        },
        {
          title: '重复发起原因',
          dataIndex: 'duplicateCause',
          width:150,
          ellipsis: true
        },
        {
          title: '备注信息',
          dataIndex: 'remarks',
          width:150,
          ellipsis: true
        },
        {
          title: '故障现象',
          dataIndex: 'errorModuleName',
          width:150,
          ellipsis: true
        },
        {
          title: '批次等级',
          dataIndex: 'batchNewLevel',
          width:150,
          ellipsis: true
        },

        {
          title: '处理方式',
          dataIndex: 'dealMethod',
          width:150,
          ellipsis: true
        },
        {
          title: '折扣(%)',
          dataIndex: 'discount',
          width:150,
          ellipsis: true
        },
        {
          title: '维修工单',
          dataIndex: 'reportInstallCode',
          width:150,
          ellipsis: true
        },
        {
          title: '工单状态',
          dataIndex: 'reportInstallStatus',
          width:150,
          ellipsis: true
        },


      ],
      isFenxiao:false,
      isDataOk:false
    };
  },
  computed:{
    ...mapState({
      isFenxiaoOrder: (state) => state.user.userInfo.account.marketModels,
      account: (state) => state.user.userInfo.account.account,
      }),

  },
  mounted(){
    if(this.$route.query.id){
      this.getDetail(this.$route.query.id, this.$route.query.source)
    }else{
      this.$notification.warning({
        message: "订单号错误",
        duration: 2
      });
    }
  },
  methods:{
    // 打印机编
    machineNumPrint(code){
      this.isDataOk = true;
      JsBarcode("#barcode", code, {
        // format: "pharmacode",  //条形码的格式
        format: "CODE128", //选择要使用的条形码类型
        width:2, //设置条之间的宽度
        height:60,  //条码高度
        // displayValue: false //是否显示文字信息
        textMargin:5,//设置条形码和文本之间的间距
        background:"#fff",//设置条形码的背景
        lineColor:"#000",//设置条和文本的颜色。
        valid:(res)=>{
          // 回调 true false
          // 回调 true false
        }
      })
      const timer = setTimeout(()=>{
        this.isDataOk = false;
        clearTimeout(timer);
      },100)
    },
    openWindows(salesOrderId,type) {
      this.openType = type
      this.visibleshop = true
      this.salesOrderId = salesOrderId
    },
    openWindowsR(salesOrderId,type) {
      this.visibleCancel = true
      this.openType = type
      this.salesOrderId = salesOrderId
    },
    // 驳回窗口确定
    getReason(e) {
      if(!e) {
        this.$message.warning('请输入取消原因')
        return
      }
      let data = {
        defectiveId:this.salesOrderId,
        audit:'驳回',
        auditRemark:e
      }
      this.pageLoadFlag = true
      updateStatus(data).then(res=>{
        this.$message.warning(res.data.msg)
        this.pageLoadFlag = false
        this.getDetail(this.$route.query.id)
      })
    },
    // 通过窗口确定
    cancelOk() {
      let data = {
        defectiveId:this.salesOrderId,
        audit:'通过',
        auditRemark:''
      }
      this.pageLoadFlag = true
      updateStatus(data).then(res=>{
        this.$message.warning(res.data.msg)
        this.pageLoadFlag = false
        this.getDetail(this.$route.query.id)
      })
    },
    goBack() {
      window.close()
    },
    getDetail(id, source){
      let data = {
        id:id,
        source
      }
      this.pageLoadFlag = true
      getOrderDetail(data).then(res=>{
        console.log(112, res)
        if(res.data.code == 400 ) {
          // this.$message.warning(res.data.msg)
          this.pageLoadFlag = false
          return
        }
        this.orderDetail = res.data.data
        console.log('orderDetail', this.orderDetail)
      
        this.pageLoadFlag = false
      }).catch(error=> {
        this.pageLoadFlag = false
      }).finally(()=>{
        this.pageLoadFlag = false
      })


    },
  
   
    gotoProcess(){
      let routeUrl = this.$router.resolve({
        path:'/channelOrder/progress',
        query:{
          id:this.$route.query.id
        }
    });
    window.open(routeUrl.href, "_blank")
    }
  }
};
